import React from "react";

const Enjoy = () => {
  return (
    <div className="per-section enjoy-section">
      <div className="container">
        <div className="title-enjoy text-center p-3 ">
          <h2 class="text-enjoy-title text-white ">
            Enjoy the best online casino experience at jiliko
          </h2>
          <p className="text-white">
            JILIKO casino is a unique and exciting online casino that anyone
            would enjoy playing at. Games are fun and engaging, while the app
            also makes gambling easy and accessible. Furthermore, new accounts
            get loads of bonuses and rewards from referrals codes. If you’re
            looking for an awesome online casino, who give generous bonuses,
            such as Jili Free 100, give jiliko a try!
          </p>
        </div>
      </div>
    </div>
  );
};

export default Enjoy;
