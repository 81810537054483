import React from "react";
import { Link } from "react-router-dom";

const Download = () => {
  return (
    <div className="per-section">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-6 col-md-12">
            <img
              src="https://jiliko.io/wp-content/uploads/2023/02/jiliko-mobile.jpg"
              alt="images"
              className="image-left w-100"
            />
          </div>
          <div className="col-lg-6 col-md-12 ">
            <div className="right-col ps-2 pe-0 my-5">
              <div className="section-title">
                <h2 class="">
                  DOWNLOAD THE JILIKO APP AND PLAY ANYTIME, ANYWHERE
                </h2>

                <p className="text-black">
                  We have designed our app to be simple and user-friendly. You
                  can place bets on the go, anywhere you may be with your phone.
                  Our games are available for both Android and iOS devices,
                  making it easy for you to catch up when ever you have time to
                  spare. Download our app today and receive the Jili Free 100
                  best bonus!
                </p>
              </div>
              <div className="d-flex justify-center">
                <Link to={"https://bj88ph.live/af/Zd946zCP/jiliko-io"}>
                  <button className="btn btn-danger download-btn me-3 px-4 py-3 fw-bold ">
                    DOWNLOAD JILIKO ANDROID APP
                  </button>
                </Link>
                <Link to={"https://bj88ph.live/af/Zd946zCP/jiliko-io"}>
                  <button className="btn btn-danger download-btn mx-3 px-4 py-3 fw-bold ">
                    DOWNLOAD JILIKO IOS APP
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="container">
        <div className="row">
            <div className="col">1</div>
            <div className="col">2</div>
        </div>
      </div> */}
    </div>
  );
};

export default Download;
