import React from "react";
import { Link } from "react-router-dom";

const Hero = () => {
  return (
    <div>
      <div className="hero-banner" id="hero-overlay">
        <div className="container pb-5">
          <div className="container px-5">
            <h1 class="text-title fw-bolder text-white text-center py-4">
              JILIKO online casino is a fun, secure and trustworthy gaming site
            </h1>
            <p className="text-subtitle text-white">
              JILIKO casino provides you with the best gaming experience
              possible. We are a safe and secure online casino where you can
              feel comfortable playing your favorite games. Our main focus is to
              give our customers the best bonuses possible, as well as guarantee
              their satisfaction when visiting our website.
            </p>
            <div className="d-flex justify-content-center gap-3">
              <Link to={"https://bj88ph.live/af/Zd946zCP/jiliko-io"}>
                <button className="btn btn-danger text-white rounded-0 px-4">
                  {" "}
                  SIGN IN
                </button>
              </Link>
              <Link to={"https://bj88ph.live/af/Zd946zCP/jiliko-io"}>
                <button className="btn btn-outline border-white border-2 rounded-0 px-4 text-white">
                  {" "}
                  SIGN IN
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
