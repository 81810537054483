import React from "react";
import { Link } from "react-router-dom";

const Member = () => {
  return (
    <div className="per-section member">
      <div className="container">
        <div className="row p-2">
          <div className="col-lg-6 col-md-12 col-sm-12 p-2 px-4">
            <div>
              <h2 class="text-center">
                Become a jiliko member in just three short steps
              </h2>
              <p className="text-black">
                JILIKO is proud to offer the most accurate, best and most
                trusted online casino games for all online players in the
                Philippines. jiliko.bet offers a wide variety of games for all
                gamblers, as well as a welcome bonus for new players and daily
                bonuses and promotions. To enjoy these, just join jiliko login
                and start playing right away! You can enjoy the most dynamic and
                exciting gameplay.
              </p>
              <p className="text-black">1. Click “Register”</p>
              <p className="text-black">2. Fill in the information</p>
              <p className="text-black">3. Complete the registration</p>
            </div>
            <div className="w-100 d-flex justify-content-center">
              <Link to={"https://bj88ph.live/af/Zd946zCP/jiliko-io"}>
                <button
                  type="button "
                  className="btn btn-danger btn-join-us px-3 rounded-0 "
                >
                  JOIN US
                </button>
              </Link>
            </div>
          </div>
          <div className="col-lg-6 col-md-12 col-sm-12 p-2">
            <div>
              <div class="h-image__frame-container">
                <img
                  decoding="async"
                  loading="lazy"
                  width="468"
                  height="433"
                  data-cfsrc="https://jiliko.io/wp-content/uploads/2023/02/join-img3.jpg"
                  className="wp-image-727 style-836-image style-local-639-c111-image relative w-100 "
                  srcset="https://jiliko.io/wp-content/uploads/2023/02/join-img3.jpg 468w, https://jiliko.io/wp-content/uploads/2023/02/join-img3-300x278.jpg 300w"
                  sizes="(max-width: 468px) 100vw, 468px"
                  alt=""
                  src="https://jiliko.io/wp-content/uploads/2023/02/join-img3.jpg"
                />
                <div className="img-border"></div>
                <div className="img-border-left"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Member;
