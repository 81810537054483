import React from "react";

const Bonuses = () => {
  return (
    <div className="best-bonuses">
      <div className="container text-center">
        <div className="title-best ">
          <h2 class="">JILIKO best bonuses</h2>
        </div>
        <div className="games row">
          <div className="col-lg-3 col-md-6 col-sm-12">
            <div class="card h-100">
              <img
                src="https://jiliko.io/wp-content/uploads/2023/02/PromotionInfoLanguage-1.jpg"
                class="card-img-top"
                alt="games"
              />
              <div class="card-body">
                <h4 class="">New Member Only! 300% welcome BONUS</h4>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 col-sm-12">
            <div class="card h-100">
              <img
                src="https://jiliko.io/wp-content/uploads/2023/02/PromotionInfoLanguage1.jpg"
                class="card-img-top"
                alt="games"
              />
              <div class="card-body">
                <h4 class="">Invite friends to jiliko get double bonus!</h4>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 col-sm-12">
            <div class="card h-100">
              <img
                src="https://jiliko.io/wp-content/uploads/2023/02/PromotionInfoLanguage-2.jpg"
                class="card-img-top"
                alt="games"
              />
              <div class="card-body">
                <h4 class="">
                  Lose money, cash back every day, highest bonus 1888
                </h4>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 col-sm-12">
            <div class="card h-100">
              <img
                src="https://jiliko.io/wp-content/uploads/2023/02/PromotionInfoLanguage-1.jpg"
                class="card-img-top"
                alt="games"
              />
              <div class="card-body">
                <h4 class="">New Member Only! 300% welcome BONUS</h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Bonuses;
