import React from "react";

const BetOffers = () => {
  return (
    <div className="per-section bet-offers">
      <div className="container text-center d-flex justify-content-center">
        <div className="p-2 w-75">
          <h2 class="title">
            JILIKO&nbsp;bet offers the best casino games to all players
          </h2>
          <p className="title-sub text-black">
            Every feature is easy to use and full of exciting features at jiliko
            bet. Games include slots, video poker, blackjack, roulette, craps
            and more. Each game has unique bonus features that make gameplay
            even more enjoyable. Plus, there are also numerous sports and sabong
            online betting options that all players can find to their liking
            here.
          </p>
        </div>
      </div>
      <div className="container">
        <div className="game-card d-flex flex-wrap  justify-content-center text-center  gap-3">
          <div className="col-lg-4 col-md-12 col-sm-12">
            <div class="card h-100 w-100 mx-auto ">
              <img
                src="https://jiliko.io/wp-content/uploads/2023/02/1.jpg"
                class="card-img-top card-image"
                alt="game"
              />
              <div class="card-body">
                <h5 class="card-title ">
                  <h5 class="text-danger">Online Slots</h5>
                </h5>
                <p class="card-text text-black">
                  Some quick example text to build on the card title and make up
                  the bulk of the card's content.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-12 col-sm-12">
            <div class="card h-200 w-200 mx-auto ">
              <img
                src="https://jiliko.io/wp-content/uploads/2023/02/table.jpg"
                class="card-img-top card-image"
                alt="game"
              />
              <div class="card-body">
                <h5 class="card-title ">
                  <h5 class="text-danger">TABLE GAMES</h5>
                </h5>
                <p class="card-text text-black">
                  Blending old-school charm with modern gaming tech, jiliko
                  offers the most complete table games selection to players who
                  wish to play casino games outside of land based casinos. Many
                  Philippine players like their chance at playing casino games
                  online and jiliko is here to deliver on that as well. So if
                  you are looking for a site that allows you to play roulette,
                  baccarat, and other signature casino games online in all their
                  glory, then give jiliko a try. You won’t be disappointed.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-12 col-sm-12">
            <div class="card h-300 w-300 mx-auto ">
              <img
                src="https://jiliko.io/wp-content/uploads/2023/02/home-img1.jpg"
                class="card-img-top card-image "
                alt="game"
              />
              <div class="card-body">
                <h5 class="card-title ">
                  <h5 class="text-danger">LIVE CASINO</h5>
                </h5>
                <p class="card-text text-black">
                  At jiliko bet, players get the chance to enjoy thrilling live
                  casino games in an authentic setting. With its advanced
                  technology that allows for seamless streaming and secure
                  transactions, you can enjoy these games at any time and on any
                  platform. They offer a wide range of table games, from Live
                  Blackjack, Live Roulette and Live Craps to Baccarat, Sic Bo
                  and even Live Pai Gow Poker .
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-12 col-sm-12">
            {" "}
            <div class="card h-400 w-400 mx-auto ">
              <img
                src="https://jiliko.io/wp-content/uploads/2023/02/home-img3.jpg"
                class="card-img-top card-image"
                alt="game"
              />
              <div class="card-body">
                <h5 class="card-title ">
                  <h5 class="text-danger">SABONG</h5>
                </h5>
                <p class="card-text text-black">
                  jiliko’s sportsbook is a place for players to place wagers on
                  their favorite sporting events. The site offers a
                  user-friendly interface that allows any member to navigate the
                  site with ease and place bets within minutes of signing up.
                  This amazing bookie has an extensive list of sporting events
                  to choose from, namely football, soccer, tennis, basketball,
                  hockey and other popular events.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-12 col-sm-12">
            {" "}
            <div class="card h-500 w-500 mx-auto ">
              <img
                src="https://jiliko.io/wp-content/uploads/2023/02/home-img4.jpg"
                class="card-img-top  card-image"
                alt="game"
              />
              <div class="card-body">
                <h5 class="card-title ">
                  <h5 class="text-danger">SABONG</h5>
                </h5>
                <p class="card-text text-black">
                  jiliko.bet’s sabong online is not a traditional casino site,
                  but it has the best casino games available, with a friendly
                  and professional staff. JILIKO is committed to providing you
                  with a unique combination of innovative entertainment,
                  excellent service and delightful experiences.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BetOffers;
