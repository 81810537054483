import React from "react";
import Hero from "../components/home/Hero";
import '../styles/home.css'
import Excellent from "../components/home/Excellent";
import Bonuses from "../components/home/Bonuses";
import BetOffers from "../components/home/BetOffers";
import Member from "../components/home/Member";
import Join from "../components/home/Join";
import Download from "../components/home/Download";
import Enjoy from "../components/home/Enjoy";
const Home = () => {
  return (
    <div>
      <Hero />
      <Excellent/>
      <Bonuses/>
      <BetOffers/>
      <Member/>
      <Join/>
      <Download/>
      <Enjoy/>
    </div>
  );
};

export default Home;
