import React from "react";
import { useState } from "react";
// import { navMenu } from '../../data/navMenu'
import { FaSquareFacebook } from "react-icons/fa6";
import { navMenu } from "../../Data/NavMenu";
import { Link, NavLink } from "react-router-dom";
const Navbar = () => {
  const [menu, setMenu] = useState("");

  const handleNavMenu = (clicked) => {
    setMenu(clicked);
  };
  return (
    <div>
      <header className="header-top">
        <div className="container">
          <div className="d-flex justify-content-end align-items-center py-2">
            <Link to={"https://bj88ph.live/af/Zd946zCP/jiliko-io"}>
              <button className="btn btn-danger px-4">LOGIN</button>{" "}
            </Link>
            <Link to={"https://bj88ph.live/af/Zd946zCP/jiliko-io"}>
              <button className="btn bg-white text-danger px-4 ms-3">
                SIGN UP
              </button>
            </Link>
            <Link to={"https://bj88ph.live/af/Zd946zCP/jiliko-io"}>
              <button className="btn ms-2">
                <FaSquareFacebook size={40} className="text-white" />
              </button>
            </Link>
          </div>
        </div>
      </header>
      <div className="container py-3">
        <div className="title">
          <a href="https://bj88ph.live/af/Zd946zCP/jiliko-io">
            <h4 className="text-center fw-bold h-logo__text">
              JILIKO BET ONLINE CASINO ENJOY JILI SLOT BETTING
            </h4>
          </a>
        </div>
        <nav class="navbar navbar-expand-lg">
          <div class="container-fluid justify-content-end">
            <div
              class="container collapse navbar-collapse d-flex justifty-content-between d-none d-lg-flex"
              id="navbarSupportedContent"
            >
              <ul class="navbar-nav me-auto mb-2 mb-lg-0 d-flex flex-wrap justify-content-center relative">
                {navMenu.map((item) => (
                  <a href="https://bj88ph.live/af/Zd946zCP/jiliko-io">
                    <li
                      key={item}
                      className={`mx-2 nav_menu ${
                        item === menu ?? item === "Home" ? "border-color  " : ""
                      } curser-pointer`}
                      onClick={() => handleNavMenu(item)}
                    >
                      <NavLink
                        to={
                          item === "Home"
                            ? "/"
                            : `/${item.toLowerCase().replace(/\s+/g, "-")}`
                        }
                      >
                        <span className="fw-medium fs-5 text-black text-uppercase">
                          {item}
                        </span>
                      </NavLink>
                    </li>
                  </a>
                ))}
              </ul>
            </div>
            <button
              class="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span class="navbar-toggler-icon"></span>
            </button>
          </div>
        </nav>
      </div>
    </div>
  );
};

export default Navbar;
