import React from "react";

const Excellent = () => {
  return (
    <div>
      <div className="container py-4">
        <div className="p-3 my-5">
          <img
            decoding="async"
            fetchpriority="high"
            width="1200"
            height="480"
            src="https://jiliko.io/wp-content/uploads/2023/08/jiliko-welcome.webp"
            class="wp-image-1264 style-941-image style-local-639-c5-image w-100"
            srcset="https://jiliko.io/wp-content/uploads/2023/08/jiliko-welcome.webp 1200w, https://jiliko.io/wp-content/uploads/2023/08/jiliko-welcome-300x120.webp 300w, https://jiliko.io/wp-content/uploads/2023/08/jiliko-welcome-1024x410.webp 1024w, https://jiliko.io/wp-content/uploads/2023/08/jiliko-welcome-768x307.webp 768w"
            sizes="(max-width: 1200px) 100vw, 1200px"
            alt="images"
          />
        </div>
        <div className="container">
          <div className="text-center">
            <div class="h-heading__outer style-762 style-local-639-c9">
              <h2 class="">
                jiliko casino is an excellent place to play games and win money
              </h2>
            </div>
            <div
              data-colibri-id="639-c10"
              class="h-text h-text-component style-763 style-local-639-c10 position-relative h-element"
            >
              <div class="">
                <p className="text-black">
                  jiliko casino is an exciting online casino that provides a fun
                  and thrilling gaming experience. It has many games to choose
                  from and lets you play on your phone or computer. You can
                  apply for an account here right away to claim the best welcome
                  bonus and enjoy the best casino gaming experience.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Excellent;
