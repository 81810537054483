import React from 'react'

const Footer = () => {
  return (
    <div className='relative'>
      <div className="container pt-3">
        <p className='text-black'>© 2023 JILIKO online casino is a fun, secure and trustworthy gaming site </p>
      </div>
    </div>
  )
}

export default Footer