import React from "react";

const Join = () => {
  return (
    <div className="join-section">
      <div className="container">
        <div className="row mx-auto">
          <div className="col-lg-6 col-md-12 px-2 px-sm-5">
            <div className="join-text">
              <div className="text-title-join pe-5">
                <h3 class="text-white">Why should you join jiliko?</h3>

                <p className="text-white">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Integer iaculis metus eget libero convallis, a sagittis elit
                  commodo. Aliquam ultricies dui orci, id rutrum lectus
                  pellentesque ut.
                </p>
              </div>
              <div className="row row-cols-2">
                <div className="col">
                  <div class="w-100 h-y-container h-column__content h-column__v-align flex-basis-100 align-self-lg-start align-self-md-start align-self-start">
                    <div
                      data-colibri-id="639-c119"
                      class="h-global-transition-all h-heading style-366 style-local-639-c119 position-relative h-element"
                    >
                      <div class="h-heading__outer style-366 style-local-639-c119">
                        <h5 class="text-white">Software providers</h5>
                      </div>
                    </div>
                    <div
                      data-colibri-id="639-c120"
                      class="h-text h-text-component style-367 style-local-639-c120 position-relative h-element"
                    >
                      <div class="">
                        <p className="text-white">
                          At jiliko login, we bring you the highest quality and
                          best choice of casino games to ensure your success.
                          Our casino games are provided by a wide range of
                          gaming software providers such as JILI, PG slot, FA
                          chai, JDB, KA gaming, EVO gaming, AE gaming and more.
                          All our platforms have been carefully selected to
                          ensure you have an excellent gaming experience.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col">
                  {" "}
                  <div
                    data-colibri-id="639-c120"
                    class="h-text h-text-component style-367 style-local-639-c120 position-relative h-element"
                  >
                    <div class="">
                      <p className="text-white">
                        At jiliko login, we bring you the highest quality and
                        best choice of casino games to ensure your success. Our
                        casino games are provided by a wide range of gaming
                        software providers such as JILI, PG slot, FA chai, JDB,
                        KA gaming, EVO gaming, AE gaming and more. All our
                        platforms have been carefully selected to ensure you
                        have an excellent gaming experience.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col">
                  {" "}
                  <div
                    data-colibri-id="639-c120"
                    class="h-text h-text-component style-367 style-local-639-c120 position-relative h-element"
                  >
                    <div class="">
                      <p className="text-white">
                        At jiliko login, we bring you the highest quality and
                        best choice of casino games to ensure your success. Our
                        casino games are provided by a wide range of gaming
                        software providers such as JILI, PG slot, FA chai, JDB,
                        KA gaming, EVO gaming, AE gaming and more. All our
                        platforms have been carefully selected to ensure you
                        have an excellent gaming experience.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col">
                  {" "}
                  <div
                    data-colibri-id="639-c120"
                    class="h-text h-text-component style-367 style-local-639-c120 position-relative h-element"
                  >
                    <div class="">
                      <p className="text-white">
                        At jiliko login, we bring you the highest quality and
                        best choice of casino games to ensure your success. Our
                        casino games are provided by a wide range of gaming
                        software providers such as JILI, PG slot, FA chai, JDB,
                        KA gaming, EVO gaming, AE gaming and more. All our
                        platforms have been carefully selected to ensure you
                        have an excellent gaming experience.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-12 d-flex align-items-center">
            <div class="w-100 h-y-container h-column__content h-column__v-align flex-basis-100 right-image">
              <img
                src="https://jiliko.io/wp-content/uploads/2023/02/ajora-gz6lq.jpg"
                alt="images to join "
                className="image-join w-100"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Join;
