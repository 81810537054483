// import "./App.css";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Layout from "./components/layout/Layout";
// import NoPage from "./pages/NoPage";
import Home from "./pages/Home";
import Signup from "./pages/Signup";
import "./styles/header.css";
function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route element={<Layout />}>
            <Route path="/" element={<Home />} />
            {/* <Route path="/sign-up" element={<Signup />} /> */}
            {/* <Route path="/slots" element={<Slots />} />
            <Route path="/sabong" element={<Sabong />} />
            <Route path="/live-games" element={<LiveGames />} />
            <Route path="/sports-betting" element={<SportsBetting />} /> */}
            <Route
              path="*"
              element={
                <Navigate
                  to="/"
                  // replace={true}
                />
              }
            />
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
